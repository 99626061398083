.Pagination{

    display: flex;
    width: 100%;
    margin: 3rem 0;
    justify-content: center;
    align-items: center;

    .next_prev_wrap{
        display: flex;
        
    }

    .page_number{
        width: 3rem;
        height: 3rem;
        border: 1px solid #ccc;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
        background-color: #fff;
        color: #010101;
        cursor: pointer;
        margin: 0 1rem 0 0;

        span{
            display:block;
        }
    }
    .pointed_page,
    .active{
        border: 1px solid #010101;
        box-sizing: border-box;
        background-color: #010101;
        color: #fff;
    }

}