.Textarea{
    position: relative;
    margin-bottom: 2rem;
    font-size: 2rem;
    width: 100%;
    box-sizing: border-box;
}

.active{
    margin: 0;
    position:absolute;
    font-size: 1.5rem;
    top: 3.5rem;
    color: #ccc;
}