@import url('https://fonts.googleapis.com/css2?family=Teko:wght@300;400;500&display=swap');


.Search{
    position: absolute;
    right:-29%;
    top: 35%;
    button{
        background-color: transparent;
        font-size: 1.125rem;
        text-transform: uppercase;
        font-family: 'Teko', sans-serif;
        font-weight: 500;
        color: #fff;
        border: none;
    }
    button:hover{
        cursor: pointer;
    }
}

.SearchModal{
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.75);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999;
    font-size: 1.125rem;
    text-transform: uppercase;
    font-family: 'Teko', sans-serif;
    font-weight: 500;
    color: #fff;


    button, a{
        background-color: transparent;
        color: #fff;
        top: 50px;
        right: 50px;
        position: absolute;
        font-size: 50px;
        border: none;
    }

    .ModalBody{
        background-color: red;
        background: 0 0;
        -webkit-box-shadow: none;
        box-shadow: none;
        border: none;

        form {
            max-width: 555px;
            position: relative;
            input {
                width: 100%;
                font-size: 36px;
                border: none;
                border-bottom: 3px solid rgba(255, 255, 255, 0.938);
                background: 0 0;
                color: #fff;
                padding-bottom: 12px;
                padding-right: 40px;
                outline: none;
            }
            input::-webkit-input-placeholder {
                font-size: 35px;
                color: rgba(255, 255, 255, 0.938);
            }
            input:-ms-input-placeholder {
                font-size: 35px;
                color: rgba(255, 255, 255, 0.938);
            }
            input::placeholder {
                font-size: 35px;
                color: rgba(255, 255, 255, 0.938);
            }
            button, a {
                position: absolute;
                right: 0;
                margin-bottom: 3px;
                font-size: 30px;
                color: rgba(255, 255, 255, 0.938);
                background: 0 0;
                border: none;
                cursor: pointer;
                top: 11px;
            }

        }
        
    }
}
.SearchPage{
    box-sizing: border-box;
    >div{
        display: flex;
        aside{
            width: 20%;
            padding: 10px;
            margin: 0;
        }
        .searchRez{
            width: 80%;
                .categorySer{
                    margin-top: 15px;
                    h3{
                        background-color: rgb(243, 101, 88);
                        padding: 5px;
                    }
                    .elements{
                        display: flex;
                        flex-wrap: wrap;
                        .element{
                            width: 150px;
                            border: 1px solid #000;
                            box-sizing: border-box;
                            padding: 5px;
                            margin: 5px;
                            h3{
                                background-color: rgb(243, 101, 88);
                                padding: 5px;
                                font-size: 16px;
                            }

                        }
                    }
                }     
        }
    }
}