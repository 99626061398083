.ContentAbout
    .About {

        position: relative;
        font-family: 'Teko', sans-serif;
        padding: 130px 30px;

        .ContainerWrap {
            display: flex;

            .AboutImage {
                position: relative;
                width: 40%;

                .ImageFluid {
                    max-width: 100%;
                    height: auto;
                }

                .AboutShape {
                    position: absolute;
                    right: 15px;
                    bottom: 0;

                    .Content {
                        position: absolute;
                        right: 33%;
                        top: 25%;
                        transform: rotate(-28deg);
                        line-height: 0;


                        h4 {
                            font-size: 55px;
                            color: #ffffff;
                            font-weight: 500;
                        }

                        span {
                            font-size: 40px;
                            color: #ffffff;
                            display: block;
                            font-weight: 300;
                        }
                    }
                }




            }

            .AboutUs {
                margin-left: 120px;
                max-width: 560px;

                .AboutInfo {

                    .AboutTitle {
                        font-size: 22px;
                        color: #ea1b29;
                        text-transform: uppercase;
                        line-height: 1.2;
                        display: block;
                        margin-bottom: 20px;
                    }

                    .SectionTitle {
                        font-size: 60px;
                        line-height: 1;
                        font-weight: 500;
                        margin-bottom: 70px;

                    }

                    .BlockQuote {
                        padding: 40px 60px;
                        padding-right: 90px;
                        background: #f7f7f7;
                        border-radius: 10px;
                        position: relative;
                        z-index: 1;
                        margin-bottom: 40px;

                        span {
                            font-size: 30px;
                            line-height: 1.05;
                            display: block;
                            color: #010101;
                            font-weight: 500;
                        }

                        .Quote {
                            position: absolute;
                            right: 55px;
                            top: 30px;
                            z-index: -1;
                        }
                    }

                    .SectionBorder {
                        position: relative;
                        display: block;

                        i {
                            color: #ea1b29;
                            font-size: 15px;

                            &::before {
                                position: absolute;
                                content: "";
                                width: 95px;
                                height: 2px;
                                background: #ea1b29;
                                top: -40px;
                                left: 25px;
                            }
                        }
                    }

                    .SectionDescription {
                        font-family: "Roboto", sans-serif;
                        font-size: 16px;
                        font-weight: normal;
                        color: #6a6b71;
                        margin-bottom: 15px;
                        line-height: 28px;
                        margin-bottom: 50px;

                    }


                    .AuthorInfo {
                        display: flex;
                        align-items: center;

                        .AuthorData {
                            display: flex;

                            .AuthorDegination {
                                margin-left: 25px;
                                margin-right: 50px;

                                h4 {
                                    font-size: 30px;
                                    line-height: 1.05;
                                    margin-bottom: 0;
                                }

                                span {
                                    font-size: 16px;
                                    line-height: 1.2;
                                    font-family: "Roboto", sans-serif;
                                    display: block;
                                }
                            }

                        }

                        .AboutBgShape {
                            position: absolute;
                            top: 75px;
                            left: 0;
                            right: 125px;
                            margin: 0 auto;
                            text-align: center;
                            z-index: -2;

                        }

                        .AboutPlay {
                            .AbPlayBtn {
                                background: #ea1b29;
                                color: #ffffff;
                                height: 55px;
                                width: 55px;
                                border-radius: 50%;
                                display: inline-block;
                                line-height: 55px;
                                text-align: center;
                                position: relative;

                                &:hover {
                                    color: #ffffff;
                                    // color: #ea1b29;
                                }

                                &::before {
                                    background: #fbd1d4;
                                    position: absolute;
                                    content: "";
                                    background: #ea1b29;
                                    width: 110%;
                                    height: 110%;
                                    border-radius: 50%;
                                    left: -5px;
                                    right: 0;
                                    z-index: -1;
                                    top: -4px;
                                }

                                &::after {
                                    position: absolute;
                                    content: "";
                                    width: 60px;
                                    height: 60px;
                                    background: #ea1b29;
                                    border-radius: 50%;
                                    z-index: -1;
                                    animation: video-ripple 1500ms ease-out infinite;
                                    left: -5px;
                                    top: -4px;
                                    z-index: -1;
                                }

                                span {
                                    font-size: 22px;
                                    font-weight: 500;
                                    margin-left: 15px;
                                    color: #010101;
                                }





                            }



                        }

                    }
                }
            }
        }

    }

.BlockTitle{
    text-align: center;
    position: absolute;
    z-index: 1;
    top: 20%;
    left:42%;
    


    h3{
        font-size: 80px;
        line-height: 1.1;
        text-transform: uppercase;
        color: #ffffff;
        font-weight: 500;
        margin: 20px 0;
    }
    ul{
        list-style: none;
        display:flex;
        width: 210px;
        height: 60px;
        text-align: center;
        line-height: 60px;
        margin: 0 auto;
        border: 2px solid #939392;
        border-radius: 30px;
        
        padding: 0 50px;
        box-sizing: border-box;

        li{
            &:hover a span{
                color:#ea1b29
           }
            
            
            &:not(:last-child)::after {
                position: absolute;
                color: #fff;
                content: "_";
                right: -5px;
                top: -8px;
                font-size: 20px;
                
            }

            display: inline-block;
            padding: 0px 8px;
            position: relative;
        
            a{
                text-decoration: none;
               
            }
            span{
                font-size: 22px;
                line-height: 1.1;
                color: #ffffff;
                transition: 0.3s;
            
            }

        }
    }
    
}


