body{
  background-color: #fff;
  margin:0;
  padding:0;
  position: relative;
}
header{
  overflow: hidden;
  position: relative;
}
.header_fon{
  /* position: absolute; */
    top: 0;
    left: 0;
    right: 0;
    box-sizing: border-box;
    z-index: 0;
}
.logo_info{
  display: flex;
  color: #fff;
  height: 70px;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 20px;
}
.header_content{
  top: 0;
  position: absolute;
  width: 100vw;
  z-index: 5;
  height: 1000px;
}
section.content{
  position: relative;
  margin:0 auto ;
  background-color: rgba(0, 0, 0, 0);
  
}
.search{
  position: relative;
}
.wrap{
  max-width: 1300px;
  width: 80%;
  margin: 0 auto;
}
.header_wrap_content{
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.Login a{
  color: #fff;
}

.bgc1{
  background: #f00;
}

.bgc2{
  background: #00f;
}