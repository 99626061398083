.textWrap{
    
    font-family: "Teko", sans-serif;
    padding: 11% 0% 5%;
   
    position: relative;
    margin-left: 10%;
    overflow: hidden;
    color: #ffffff;
    text-transform: uppercase;
}
.title{
    font-size: 45px;
    letter-spacing: 4px;
    margin-bottom: 40px;
    display: block;
    font-weight: 300;
}

.subTitle{
    font-size: 120px;
    line-height: 1;
    letter-spacing: 4px;
    font-weight: 600;
    margin-bottom: 0;
    margin-top: 0;
}

.mainText{
    font-size: 48px;
    font-weight: 300;
    line-height: 1.1;
}

.exploreBtn{
    padding: 0% 5%;
    text-decoration: none;
    background: #ea1b29;
    font-family: "Teko", sans-serif;
    font-size: 28px;
    color: #ffffff;
    display: inline-block;
    font-weight: 500;
    text-transform: uppercase;
    transition: 0.3s;
    display: inline-block;
    border-radius: 3px;
    overflow: hidden;
    position: relative;
    height: 60px;
    line-height: 60px;
}
.btnArrows{
    margin-left: 5px;
    font-weight: 300;
    font-size: 23px;
}
.exploreBtn:hover{
    color:#ea1b29;
    background-color: #fff;
}
.roundBtn{
    height: 55px;
    width: 55px;
    background: #ffffff;
    color: #ea1b29;
    border-radius: 50%;
    display: inline-block;
    line-height: 55px;
    text-align: center;
    position: relative;
    margin-left: 20px;
    margin-bottom: 1.25%;
}
.roundBtn::before{
    position: absolute;
    content: "";
    background: #ea1b29;
    width: 110%;
    height: 110%;
    border-radius: 50%;
    left: -5px;
    right: 0;
    z-index: -1;
    top: -4px;
}
.roundBtn:hover{
    color: #ea1b29;
}
.IconAlign{
    margin: 18px 10px 28px 10px;
}

