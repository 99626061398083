.Footer{
    background-image: url("../../../images/footer-bg.jpg");
    background-size: cover;
    background-position: center top;
    background-repeat: no-repeat;
    position: relative;
    z-index: 1;
    margin: 0;
    padding: 0;
    box-sizing: border-box;

    .FooterTopInfo{
        border-bottom: 1px solid #373738;
        .customeContainer{
            width: 1170px;
            .NewsLetterArea{
                padding-top: 100px;
                padding-bottom: 20px;

                .NewsLetterTitle{
                    font-size: 60px;
                    line-height: 1;
                    color: #ffffff;
                    margin-bottom: 30px;
                }
                .NewsLetterInfo{
                    position: relative;
                    display: flex;
                    justify-content: space-between;
                    .NewsLetterSearch{
                        margin-bottom: 30px;
                        input{
                            width: 400px;
                            height: 70px;
                            background: #ffffff;
                            color: #6a6b71;
                            border: none;
                            outline: none;
                            padding: 0 30px;
                            border-radius: 50px;
                            font-family: "Roboto", sans-serif;
                        }

                    }
                    .SubscriveButton{
                        .BtnNewsLetter{
                            padding: 0 44px;
                            background: #ea1b29;
                            font-family: "Teko", sans-serif;
                            font-size: 18px;
                            color: #ffffff;
                            display: inline-block;
                            font-weight: 500;
                            text-transform: uppercase;
                            transition: 0.3s;
                            display: inline-block;
                            border-radius: 50px;
                            overflow: hidden;
                            position: relative;
                            border: 2px solid transparent;
                            height: 70px;
                            line-height: 70px;

                            &:hover{
                                background-color: #fff;
                                color: #ea1b29;
                                transition: 1s;
                            }

                            &:before {
                                background-color: #fff;
                                content: "";
                                height: 150px;
                                left: -75px;
                                position: absolute;
                                top: -35px;
                                transform: rotate(35deg);
                                transition: all 1600ms cubic-bezier(0.19, 1, 0.22, 1);
                                width: 40px;
                                opacity: 0;
                            }

                            i{
                                margin-left: 10px;
                                font-size: 15px;
                            }
                        }

                    }
                }
            }
        }
    }

    .FooterContent{
        padding-top: 80px;
        padding-bottom: 45px;
        .customeContainer{
            width: 1170px;
            .FooterWidget{
                // margin-right: 90px;
                margin-bottom: 40px;
                .FooterLogo{
                    margin-bottom: 25px;
                    .NavLink{
                        text-decoration: none;
                        color: inherit;
                        outline: none;
                        border: none;
                        background: transparent;
                    }
                }
                .Text{
                    color: #ffffff;
                    font-size: 15px;
                    margin-bottom: 0; 
                    padding-bottom: 30px;
                }

                .FooterSocialLinks{
                    .NavLink{
                        display: inline-block;
                        width: 45px;
                        height: 45px;
                        line-height: 47px;
                        text-align: center;
                        background: #424243;
                        color: #ffffff;
                        margin-right: 10px;
                        margin-bottom: 10px;
                        transition: 0.3s;
                    }
                }

                .FooterWidgetTitle{
                    font-size: 30px;
                    color: #ffffff;
                    position: relative;
                    margin-bottom: 35px;
                }

                .FooterProjectLists, .FooterInfo{
                    list-style: none;
                    margin: 0px;
                    padding: 0px;

                    .NavLink{
                        font-size: 16px;
                        color: #ffffff;
                        font-family: "Roboto", sans-serif;
                        display: inline-block;
                        transition: 0.3s;
                        text-decoration: none;

                    
                    }
                }

            }
        }
    }
}
