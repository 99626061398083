.product{
    padding: 0 0.5rem;
    width: 33.33%;
    margin:0 1rem 0 0;
}
.products{
    display: flex;
    flex-wrap: wrap;
    margin: 30px auto 0;
}
.Sorting{
   margin-top: 4%;
   margin-left: 4%;
}