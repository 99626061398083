@import url('https://fonts.googleapis.com/css2?family=Teko:wght@300;400;500&display=swap');
.active{
    color:#f00;
}
.MainNav{
    z-index: 5;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    background-color: #ffffff38;
    font-size: 1.125rem;
    text-transform: uppercase;
    font-family: 'Teko', sans-serif;
    font-weight: 500;
    color: #fff;
}
.MainNav nav{
    width: 100%;
    margin-left: 10px;
}
.MainNav ul{
   display: flex;
   flex-wrap: wrap;
   align-items: center;
   justify-content: space-between;
   margin: 0;
   padding: 1.65em 0;
   list-style: none;
   width: 70%;
   position: relative;
}
.MainNav ul li{
    display:block;
}

.nav_link{
    text-decoration: none;
    color: #fff;
}
.nav_link:hover{
    color: #f00;
}
.MainNav ul ul{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    z-index: 5;
    opacity: 0; 
    visibility: hidden;
    transition-property: opacity, visibility; 
    transition-duration: 0.2s;
    transition-delay: 0.15s;
    position: absolute;
    background: #fff;
    width: 10%;
    box-sizing: border-box;
    padding: 1em;
    border-top: 3px solid #f00;
    margin-top: 1.65em;
}
.MainNav ul ul li a span{
    text-align: start;

}

.MainNav ul li:hover ul{
    opacity: 1; 
    visibility: visible;
}
.MainNav ul ul .nav_link{
    color: #000;
}
.MainNav ul ul .nav_link:hover{
    color: #f00;
}






