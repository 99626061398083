.Product{
   padding: 0;
   position: relative;
    .image{
        position: relative;
        overflow: hidden;
        margin-bottom: 0.5rem;
        display: flex;
        align-items: center;
        justify-content: center;

        img{
            display: block;
            height: auto;
            width: 190%;
        }
    }
   
    .tag{
        position: absolute;
        background-color: #ea1b29;
        padding: .5rem 2.2rem;
        text-transform: uppercase;
        color: #fff;
        font-size: 1.5rem;
        bottom: 23rem;
        left: 1rem;
    }
    .wrap_bottom{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: flex-start;
    }
    .button{
        font-size: 1.3rem;
        padding: .5rem 0;
        width: 40%;
        color: #000;
        border: 1px solid #ea1b29;
        box-sizing: border-box;
        border-radius: 3rem;
        text-align: center;
        text-transform: uppercase;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        span{
            display:block
        }
        .btnArrows{
            display: block;
            font-size: 1.3rem;
            padding: 0 0.2rem 0.3rem 0.3rem;
        }
    }
    .product_info{
        width: 50%;
    }
    .product_link{
        text-decoration: none;
    }
    .product_name{
        font-size: 1.5rem;
        color: #000;
    }
    .price{
        color: #ea1b29;
        font-size: 1.2rem;
    }
}
