.login{
    padding: 0 40px;
    //background: #ea1b29;
    color: #ffffff;
    font-size: 18px;
    font-weight: 500;
    font-family: "Teko", sans-serif;
    text-transform: uppercase;
    transition: 0.3s;
    position: relative;
    display: inline-block;
    height: -webkit-fill-available;
    text-align: center;
    line-height: 80px;
    text-decoration: none;
    margin-left: 10px;
}
.NavBar{
    display: flex;
  
}