@import url('https://fonts.googleapis.com/css2?family=Teko:wght@300;400;500&display=swap');
// @import Breadcam from "../../images/breadcam-bg.jpg";


.DefaultLayout{
    font-family: 'Teko', sans-serif;

    .HeaderFon{
        background-image: url("../../../images/breadcam-bg.jpg");
        padding-top: 335px;
        padding-bottom: 180px;
        height: 90vh;
        background-size: cover;
        background-position: center top;
        background-repeat: no-repeat;
        position: relative;
        z-index: 1;

        &::before {
            position: absolute;
            width: 100%;
            height: 100%;
            background: #0009;
            content: "";
            top: 0;
            z-index: -1;
        }
    }
}
section{
    // width: 1000px; закомментировал, потому что хз, что это, и оно мешает
    margin: 0 auto;
    display: flex;
}
aside{
    width:30%;
    margin-top:40px;
    margin-right:40px;
}
